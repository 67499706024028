import React, { useRef } from "react";
import TitleTextBox from "../common/title_text";
import useScreenResize from "../common/useScreenResize";
import StudentPartners from "./student_partners";

// Premium Partners
import Equifax from "./logos/Equifax_Logo.png";
import Invesco from "./logos/Invesco-qqq.svg";
import Fiserv from "./logos/Fiserv_logo.png";
// Other Partners
import Truist from "./logos/Truist.svg";
import LexisNexis from "./logos/LexisNexis.svg";
import Evestment from "./logos/evestment-logo.png";
import WF from "./logos/wf.png";
import VOYA from "./logos/VOYA.png";
import ICE from "./logos/ICE.png";
import ICBA from "./logos/ICBA.png";
import Snowflake from "./logos/snowflake.png";
import Darpa from "./logos/DARPA_Logo_2010.png";
import Polygon from "./logos/polygon.io.png";
// Infrastructure Partners
import Azure from "./logos/azure.png";
import DE from "./logos/DE.png";
// Social Impact Partners
import Pin from "./logos/pin.png";
import Speedwell from "./logos/Speedwell.png";
// Georgia Tech Partners
import QCF from "./logos/QCF.png";
import ML from "./logos/ML.png";
import Security from "./logos/Security.png";
import CFT from './logos/Web_CFT_Navy 1.png'
// Student Partners
import Blockchain from "./logos/Blockchain_Club.png";
import Trading from "./logos/Trading_Club.png";
import Computational from "./logos/Computational_Club.png";
import FMA from "./logos/FMA.png";

function Partners() {
  const navbarRef = useRef(null);
  const { isMobile, navbarHeight } = useScreenResize(navbarRef);
  const appliedStyles = isMobile ? mobile_styles : styles;

  return (
    <div>
      <TitleTextBox title="Premium Partners" hasDivder={true} />
      <div style={appliedStyles.logoContainer}>
        <img src={Equifax} alt="Equifax" style={appliedStyles.logoImage} />
        <img src={Invesco} alt="Invesco" style={{ maxWidth: "300px" }} />
        <img src={Fiserv} alt="Fiserv" style={{ maxWidth: "100px" }} />
      </div>

      <TitleTextBox title="(Other) Partners" hasDivder={true} />
      <div style={{ 
        ...appliedStyles.logoContainer, 
        ...(isMobile ? {} : { padding: "20px 20px 40px" })
      }}>
        <img src={Truist} alt="Truist" style={appliedStyles.logoImage} />
        <img
          src={LexisNexis}
          alt="LexisNexis"
          style={appliedStyles.logoImage}
        />
        <img src={Evestment} alt="Evestment" style={appliedStyles.logoImage} />
        <img src={Snowflake} alt="Snowflake" style={appliedStyles.logoImage} />
      </div>
      <div style={{ 
        ...appliedStyles.logoContainer, 
        ...(isMobile ? {} : { gap: "80px" })
      }}>
        <img src={Darpa} alt="DARPA" style={appliedStyles.logoImage} />
        <img src={Polygon} alt="Polygon.io" style={appliedStyles.logoImage} />
        <img src={ICBA} alt="ICBA" style={appliedStyles.logoImage} />
        <img src={WF} alt="WF" style={appliedStyles.logoImage} />
        <img src={ICE} alt="ICE" style={appliedStyles.logoImage} />
        <img src={VOYA} alt="VOYA" style={appliedStyles.logoImage} />
      </div>

      <TitleTextBox title="Infrastructure Partners" hasDivder={true} />
      <div style={appliedStyles.logoContainer}>
        <img src={Azure} alt="Azure" style={appliedStyles.logoImage} />
        <img src={DE} alt="DE" style={{ maxWidth: "300px" }} />
      </div>

      <TitleTextBox title="Social Impact Partners" hasDivder={true} />
      <div style={appliedStyles.logoContainer}>
        <img src={Pin} alt="Pin" style={appliedStyles.logoImage} />
        <img src={Speedwell} alt="Speedwell" style={{ maxWidth: "300px" }} />
      </div>

      <TitleTextBox title="Georgia Tech Partners" hasDivder={true} />
      <div style={appliedStyles.logoContainer}>
        <img src={QCF} alt="QCF" style={appliedStyles.logoImage} />
        <img src={ML} alt="ML" style={appliedStyles.logoImage} />
        <img src={Security} alt="Security" style={appliedStyles.logoImage} />
        <img src={CFT} alt="CFT" style={appliedStyles.logoImage} />
      </div>

      <TitleTextBox title="Student Partners" hasDivder={true} />
      <div style={appliedStyles.logoContainer}>
        <StudentPartners
          imgf={Blockchain}
          text="Georgia Tech Blockchain Club"
        />
        <StudentPartners imgf={Trading} text="Georgia Tech Trading Club" />
        <StudentPartners
          imgf={Computational}
          text="Georgia Tech Computational Finance Club"
        />
        <StudentPartners imgf={FMA} text="Financial Management Association" />
      </div>

      <TitleTextBox title="Partner with us" hasDivder={false} />
      <div style={appliedStyles.cardContainer}>
        <p style={appliedStyles.myDescription}>
          To collaborate with our lab, please email Dr. Sudheer Chava
          (Director): <span style={{ color: "#003057" }}>chava@gatech.edu</span>
        </p>
      </div>
    </div>
  );
}

/* styles */
const styles = {
  logoContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    gap: "120px",
    padding: "20px 20px 180px",
  },

  logoImage: {
    maxWidth: "200px",
  },

  cardContainer: {
    margin: "0px 240px 50px",
    height: "135px",
    backgroundColor: "rgba(217, 217, 217, 0.35)",
  },

  myDescription: {
    color: "#54585A",
    textAlign: "left",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "45px",
    padding: "21px 45px",
    backgroundColor: "rgba(217, 217, 217, 0.35)",
  },
};

const mobile_styles = {
  logoContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    gap: "25px",
    padding: "13px 63px 100px",
  },

  logoImage: {
    maxWidth: "200px",
  },

  cardContainer: {
    margin: "0px 30px 100px",
    height: "95px",
    backgroundColor: "rgba(217, 217, 217, 0.35)",
  },

  myDescription: {
    color: "#54585A",
    textAlign: "left",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "18px",
    padding: "17px 20px",
  },
};

export default Partners;
