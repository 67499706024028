import React, { useState, useEffect } from "react";
import { Card, Button, Container, Row, Col } from "react-bootstrap";
import { Element, animateScroll as scroll } from "react-scroll";
import "../../App.css";
import "../research/research.css";
import { Link as Link1 } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { getResearchList } from "../research/researchService";
import { researchList, updateResearchList } from "../../store";
import Summarycard from "../research/summarycard";

function Research() {
  const [isNavVisible, setNavVisibility] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const researchFilterList = [
    {
      name: "Artificial Intelligence",
      selected: true,
      researchField: ["ML and NLP in Finance"],
    },
    {
      name: "Financial Economics",
      selected: false,
      researchField: [
        "Banking",
        "Credit Risk",
        "Covenants in Bond and Loan Contracts",
        "Corporate Finance",
        "Stock Returns and Asset Management",
      ],
    },
    {
      name: "Fintech and Crypto",
      selected: false,
      researchField: ["Crypto Currencies and Blockchain", "FinTech"],
    },
    {
      name: "Real Estate",
      selected: false,
      researchField: ["Real Estate"],
    },
    {
      name: "Responsible and Sustainable Finance",
      selected: false,
      researchField: [
        "Responsible Finance",
        "Household Finance",
        "Small Business",
        "Municipal Finance",
      ],
    },
  ];
  const [researchOption, setResearchOption] = useState(researchFilterList);
  const [currentResearchField, setCurrentResearchField] = useState(["ML and NLP in Finance"]);
  const handleMediaQueryChange = (mediaQuery) => {
    if (mediaQuery.matches) {
      setIsSmallScreen(true);
    } else {
      setIsSmallScreen(false);
    }
  };

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 700px)");
    mediaQuery.addListener(handleMediaQueryChange);
    handleMediaQueryChange(mediaQuery);

    return () => {
      mediaQuery.removeListener(handleMediaQueryChange);
    };
  }, []);
  const { loading, error, data } = useQuery(getResearchList, {
    skip: researchList.length !== 0,
  });
  if (loading || error) return "";
  if (data) {
    const sortedResearch = data.allResearch
      .slice()
      .reverse()
      .sort((a, b) => b.publicationYear - a.publicationYear);
    updateResearchList(sortedResearch);
  }
  const toggleNav = () => {
    setNavVisibility(!isNavVisible);
  };

  const updateSelection = (filter) => {
    const newResearchList = researchOption.map((res) => {
      return {
        name: res.name,
        selected: res.name === filter.name,
        researchField: res.researchField,
      };
    });
    setResearchOption(newResearchList);
    setCurrentResearchField(filter.researchField);
  };

  return (
    <div className="research-main-page">
      <div className="research-filter-container">
        {researchOption.map((option) => {
          return (
            <div
              className={`research-filter ${
                option.selected ? "research-filter-selected" : ""
              }`}
              key={option.name}
              onClick={() => updateSelection(option)}
            >
              {" "}
              {option.name}
            </div>
          );
        })}
      </div>
      <Container fluid>
        <div class="research-summary-container">
          {researchList.map((research, i) => {
            if (currentResearchField.includes(research.researchField)) {
              return (
                <Summarycard key={i} id={research.id} research={research} />
              );
            }
          })}
        </div>
      </Container>
    </div>
  );
}

export default Research;
