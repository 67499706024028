import { gql } from '@apollo/client'

export const getResearchList = gql`{
    allResearch {
      elementName
      hasResearch
      researchField
      researchName
      publicationName
      publicationYear
      researchWebsite
      researchAuthors {
        name
        website
      }
      id
      abstract
      additionalContent
      image {
        fileName
        url
      }
    }
  }    
`
