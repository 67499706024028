import React, { useState, useEffect, useRef } from "react";
import useScreenResize from "../common/useScreenResize";

function NewsCards({ header, authors, publicationName, publicationYear, pic, newsLink }) {
  const navbarRef = useRef(null);
  const { isMobile, navbarHeight } = useScreenResize(navbarRef);
  const appliedStyles = isMobile ? mobile_styles : styles;

  const authorNames = authors.map((author) => author.name).join(", ");

  return (
    <div style={appliedStyles.newsCards}>
      <div>
        <p style={appliedStyles.newsCardsHeader}>{header}</p>
        {isMobile ? (
          <button style={appliedStyles.newsCardsButton}>READ MORE</button>
        ) : (
          // <p style={styles.newsCardsDescription}>{}</p>
          <div style={appliedStyles.researchInfo}>
            <p>
              <b>Authors</b>
            </p>
            <p style={{ lineHeight: '1.2' }}>{authorNames}</p>
            <p>
              <b>Publication</b>
            </p>
            <p>{publicationName}</p>
            <p>
              <b>Year</b>
            </p>
            <p>
              {publicationYear}
            </p>
          </div>
        )}
      </div>
      <div style={appliedStyles.newsCardsMedia}>
        <img style={appliedStyles.newsCardsPicture} src={pic.url} alt="News" />
        <a href={newsLink}>
          {isMobile ? null : (
            <button style={appliedStyles.newsCardsButton}>READ MORE</button>
          )}
        </a>
      </div>
    </div>
  );
}

const styles = {
  newsCards: {
    display: "flex",
    flexDirection: "row",
    height: "385px",
    padding: "30px",
    backgroundColor: "rgba(214, 219, 212, 0.35)",
    borderRadius: "5px",
    gap: "28px",
    width: "750px",
  },

  researchInfo: {
    display: "grid",
    width: "400px",
    gridTemplateColumns: "1fr 2fr",
    gap: "2px",
    textAlign: "start",
  },

  newsCardsMedia: {
    display: "flex",
    flexDirection: "column",
    gap: "30px",
  },

  newsCardsPicture: {
    borderRadius: "5px",
    height: "238px",
    paddingRight: "20px",
    maxWidth: "None",
  },

  newsCardsButton: {
    // height: "50px",
    alignSelf: "center",
    borderRadius: "10px",
    background: "#003057",
    color: "white",
    fontSize: "18px",
    fontWeight: 700,
    border: 0,
    padding: "12px 20px",
    // boxShadow: 0,
  },

  newsCardsHeader: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "left",
    padding: 0,
  },

  newsCardsDescription: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "normal",
    textAlign: "justify",
    height: "228px",
    padding: "0 0 20px 0",
  },
};

const mobile_styles = {
  newsCards: {
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    height: "315px",
    padding: "30px",
    backgroundColor: "rgba(214, 219, 212, 0.35)",
    borderRadius: "5px",
    maxWidth: "600px",
  },

  newsCardsPicture: {
    borderRadius: "5px",
    width: "270px",
    height: "238px",
    flexShrink: 1,
  },

  newsCardsHeader: {
    fontFamily: "'Roboto', sans-serif",
    fontSize: "20px",
    fontWeight: 700,
    lineHeight: "normal",
    textAlign: "left",
    maxWidth: "229px",
    minWidth: "200px",
    maxheight: "156px",
    padding: "10px 28px 10px 0",
  },

  newsCardsButton: {
    maxWidth: "160px",
    height: "50px",
    borderRadius: "10px",
    background: "#003057",
    color: "white",
    fontSize: "18px",
    fontWeight: 700,
    margin: "auto",
  },
};

export default NewsCards;
