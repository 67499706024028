import React, { useState, useEffect, useRef } from 'react';
import { useQuery } from '@apollo/client';
import { getNews } from '../news/newsService';
import { getResearchList } from '../research/researchService';
import NewsCards from './about_news_card';
import useScreenResize from '../common/useScreenResize';
function AboutNewsList() {
    const { loading, error, data } = useQuery(getResearchList);
    const navbarRef = useRef(null);
    const { isMobile, navbarHeight } = useScreenResize(navbarRef); 

    if (loading || error)   
        return ""
    const slicedNewsArray = isMobile 
        ? data.allResearch.filter(news => news.elementName === 'SubjectiveQA') 
        : ['SubjectiveQA', 'SBFS', 'CocoHD', 'NumClaimFinance'].map(name => 
            data.allResearch.find(news => news.elementName === name)
        ).filter(Boolean);

    return (
        slicedNewsArray.map((news, i) => {
            console.log(news)
            return (
                <div key={i}>
                    <NewsCards
                        header={news.researchName}
                        authors={news.researchAuthors}
                        publicationName={news.publicationName}
                        publicationYear={news.publicationYear}
                        pic={news.image[0]}
                        newsLink={news.researchWebsite}
                    />
                    <br />
                </div>
            );
        })
    )

}

export default AboutNewsList;
